import Price from '../../common/components/price/Price';
import { PinEntryDevice } from '../api';

export default function CardFailureConfirmationFinal({
  onClick,
  ped,
  amount,
}: {
  onClick: () => void;
  ped: PinEntryDevice;
  amount: number;
}) {
  return (
    <div className="pos-modal pos-modal_role_sign-in">
      <div className="pos-modal__content">
        <div className="pos-sign-in js-sign-in">
          <div className="pos-sign-in__header">
            <h2 className="pos-sign-in__title">Payment Failure Confirmation</h2>
          </div>
          <div className="pos-sign-in__info">
            <p className="pos-sign-in__label">
              You are now confirming that the payment has failed, and that you have checked the card machine to make sure the payment has
              failed.
              <br />
              <br />
              You have selected that{' '}
              <span style={{ color: 'red' }}>
                {ped.name} ({ped.terminalId})
              </span>{' '}
              was the card machine that has just failed payment
              <br />
              <br />
              You have said that{' '}
              <span style={{ color: 'red' }}>
                <Price price={amount} />
              </span>{' '}
              was the amount for the last successful amount on this card machine.
              <br />
              <br />
              <br />
              If you are unsure, please click in the grey area to restart the process.
            </p>
          </div>
          <div className="pos-sign-in__actions">
            <button className="pos-sign-in__action" type="button" onClick={onClick}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
