import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../../app/redux/hooks';
import appLogger from '../../../common/logger/AppLogger';
import { appSubscriptionService } from '../../../common/subscriptions/appSubscriptionService';
import {
  useGetEngineInformationQuery,
  useGetSitesQuery,
  useGetWorkstationsQuery,
  useLazyGetLayoutDefinitionsQuery,
  useLazyGetMenuCategoriesQuery,
  useLazyGetMenuCurrentVersionQuery,
  useLazyGetMessagePresetsQuery,
  useLazyGetUnitOfSalesQuery,
  useLazyGetVoidReasonsQuery,
  useLazyGetPackagesQuery,
  useLazyStartupQuery,
} from '../../api';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import { setCardStatus, useCardStatus } from '../../welcome/signOnSlice';
import { useAppStateMenuDataChanged, useAppStateSignalrConnected } from '../appStateSelectors';
import { addLoadError, setEngineInfo, setSite, setWorkstation } from '../appStateSlice';

function ApplicationStateManager() {
  const hasLoaded = useRef(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const workstation = useGetWorkstationsQuery();
  const site = useGetSitesQuery();
  const engine = useGetEngineInformationQuery();
  const signalRConnected = useAppStateSignalrConnected();
  const menuDataChanged = useAppStateMenuDataChanged();
  const cardStatus = useCardStatus();

  const [triggerMenuVersion] = useLazyGetMenuCurrentVersionQuery();
  const [triggerMenuCategories] = useLazyGetMenuCategoriesQuery();
  const [triggerLayoutDefinitions] = useLazyGetLayoutDefinitionsQuery();
  const [triggerUnitOfSales] = useLazyGetUnitOfSalesQuery();
  const [triggerVoidReasons] = useLazyGetVoidReasonsQuery();
  const [triggerMessagePresets] = useLazyGetMessagePresetsQuery();
  const [triggerPackages] = useLazyGetPackagesQuery();

  const [triggerStartWorkstation] = useLazyStartupQuery();

  useEffect(() => {
    if (workstation.data !== undefined) dispatch(setWorkstation(workstation.data));
  }, [workstation.data, dispatch]);

  useEffect(() => {
    if (workstation.isError === true) dispatch(addLoadError('Workstation'));
  }, [workstation.isError, dispatch]);

  useEffect(() => {
    if (site.data !== undefined) dispatch(setSite(site.data));
  }, [site.data, dispatch]);

  useEffect(() => {
    if (site.isError === true) dispatch(addLoadError('Site'));
  }, [site.isError, dispatch]);

  useEffect(() => {
    if (engine.data !== undefined) dispatch(setEngineInfo(engine.data));
  }, [engine.data, dispatch]);

  useEffect(() => {
    if (engine.isError === true) dispatch(addLoadError('Engine'));
  }, [engine.isError, dispatch]);

  useEffect(() => {
    if (signalRConnected === false) {
      appLogger.warn('SignalR Not Connected, dispatch connect');
      dispatch({
        type: 'signalr/connect',
      });
    }
  }, [signalRConnected, dispatch]);

  useEffect(() => {
    const sub = appSubscriptionService.cardResultStatusObservable().subscribe((val) => {
      const existingIndex = cardStatus.findIndex((p) => p.terminalId == val.terminalId);
      if (existingIndex > -1) {
        const newItems = [...cardStatus];
        newItems[existingIndex] = val;
        dispatch(setCardStatus(newItems));
      } else {
        const newItems = [...cardStatus];
        newItems.push(val);
        dispatch(setCardStatus(newItems));
      }
    });

    return () => sub.unsubscribe();
  }, [dispatch, cardStatus]);

  useEffect(() => {
    const inter = setInterval(() => {
      const currentTime = new Date().getTime();
      const expiredItems = cardStatus.filter((p) => currentTime - p.dateTimeAdded < 15 * 60 * 1000);
      dispatch(setCardStatus(expiredItems));
    }, 10000);

    return () => clearInterval(inter);
  }, [cardStatus, dispatch]);

  useEffect(() => {
    if (workstation.data !== undefined && signalRConnected === true && hasLoaded.current === false) {
      hasLoaded.current = true;
      triggerStartWorkstation().catch(() => dispatch(showErrorDialog({ message: 'Failed to Start Workstation', dismissible: true })));
    }
  }, [signalRConnected, dispatch, triggerStartWorkstation, workstation]);

  useEffect(() => {
    const sub = appSubscriptionService.errorButtonAlternateClickedObservable().subscribe((val) => {
      if (val.type === 'signalr-disconnected') window.location.reload();
    });
    return () => sub.unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const sub = appSubscriptionService.killObservable().subscribe(() => {
      navigate('/offline');
    });
    return () => sub.unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (workstation?.data !== undefined) {
      triggerMenuVersion()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading menu items', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Menu Items'));
        });
      triggerMenuCategories()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading menu categories', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Menu Categories'));
        });
      triggerLayoutDefinitions()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading layout definitions', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Layout Definitions'));
        });
      triggerUnitOfSales()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading unit of sales', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Unit of Sales'));
        });
      triggerVoidReasons()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading void reasons', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Void Reasons'));
        });
      triggerMessagePresets()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading message presets', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Message Presets'));
        });
      triggerPackages()
        .unwrap()
        .catch((err) => {
          appLogger.error('Error loading packages', err);
          if (err && err.status && err.status == 'FETCH_ERROR') dispatch(addLoadError('Packages'));
        });
    }
  }, [
    menuDataChanged,
    triggerMenuCategories,
    triggerMenuVersion,
    triggerLayoutDefinitions,
    triggerUnitOfSales,
    triggerVoidReasons,
    triggerMessagePresets,
    workstation,
    dispatch,
    triggerPackages,
  ]);

  useEffect(() => {
    const sub = appSubscriptionService.testObservable().subscribe(() => {
      dispatch(
        showErrorDialog({
          message: 'Test Notification',
          dismissible: true,
        }),
      );
    });

    return () => sub.unsubscribe();
  }, [dispatch]);
  return <></>;
}
export default ApplicationStateManager;
