import { useEffect, useRef, useState } from 'react';

import { useAppDispatch } from '../../../app/redux/hooks';
import { useSignalRPoll, useSignalRStatus } from '../../../features/appState';
import { showErrorDialog } from '../../../features/error-dialog/errorDialogSlice';
import appLogger from '../../logger/AppLogger';

import './pollError.sass';

export function PollError() {
  const dispatch = useAppDispatch();
  const signalRPoll = useSignalRPoll();
  const signalrStatus = useSignalRStatus();

  const [pollAlert, setPollAlert] = useState(false);
  const [disconnectedAlert, setDisconnectedAlert] = useState(false);

  const pollLastCheckTime = useRef<number>(new Date().getTime());
  const disconnectedLastCheckTime = useRef<number>(new Date().getTime());
  const disconnectedLastReconnectAttemptTime = useRef<number>(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      if (signalRPoll === 'Never') {
        const timeDiff = (new Date().getTime() - pollLastCheckTime.current) / 1000;
        appLogger.warn('signalr poll never diff is ', timeDiff);
        if (timeDiff > 30 && disconnectedAlert === false) setPollAlert(true);
      } else {
        const pollTooFar = (new Date().getTime() - new Date(signalRPoll).getTime()) / 1000;
        if (pollTooFar > 120) setPollAlert(true);
        else if (pollAlert === true) {
          appLogger.warn('Poll Alert enabled');
          setPollAlert(false);
          pollLastCheckTime.current = new Date().getTime();
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [signalRPoll, pollAlert, disconnectedAlert]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (signalrStatus == false) {
        const timeDiff = (new Date().getTime() - disconnectedLastCheckTime.current) / 1000;
        appLogger.warn('signalr not connected diff is ', timeDiff);
        if (timeDiff > 30) {
          disconnectedLastCheckTime.current = new Date().getTime();
          console.debug('GOT IN 30 SEC TIMER');
          const timeDiffReconnect = (new Date().getTime() - disconnectedLastReconnectAttemptTime.current) / 1000;
          if (timeDiffReconnect > 5) {
            appLogger.warn('Requesting connect');
            dispatch(
              showErrorDialog({
                dismissible: false,
                message: 'Currently reconnecting, please wait...',
              }),
            );
            dispatch({
              type: 'signalr/connect',
            });
            disconnectedLastReconnectAttemptTime.current = new Date().getTime();
          }
          setDisconnectedAlert(true);
        }
      } else {
        setDisconnectedAlert(false);
        disconnectedLastReconnectAttemptTime.current = new Date().getTime();
        disconnectedLastCheckTime.current = new Date().getTime();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [signalrStatus, setDisconnectedAlert, dispatch]);

  return (
    <>
      {pollAlert && (
        <div className="pos-warning-connection">
          <h3>WARNING: SERVER POLLING IS NOT RECENT</h3>
        </div>
      )}
      {disconnectedAlert && (
        <div className="pos-warning-connection">
          <h3>WARNING: SERVER WEBSOCKET IS DISCONNECTED</h3>
        </div>
      )}
    </>
  );
}
