import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Middleware } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import AppLogger from '../../common/logger/AppLogger';
import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import { MenuVersion } from '../../features/api/menu/MenuVersion';
import { clearOperatorSession, setPollData, setSignalrStatus, setWorkstationBlocked, setWorkstationClosed } from '../../features/appState';
import { login } from '../../features/auth';
import { refreshToken } from '../../features/auth/oauth';
import { clearErrorDialog, showErrorDialog } from '../../features/error-dialog/errorDialogSlice';
import { setLayoutDefinitions, setMenuItemCondiments, setMenuVersion, setPackages, setUnitOfSales } from '../../features/order/orderSlice';
import { setTenderMedias } from '../../features/tender/tenderSlice';

import { RootState } from './reducer';

// eslint-disable-next-line @typescript-eslint/ban-types
const signalrMiddleware: Middleware<{}, RootState> = (store) => {
  let connection: HubConnection | undefined;
  return (next) => (action) => {
    if (action.type === 'signalr/connect') {
      const getToken = async () => {
        const state = store.getState();
        if (state.auth.token) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const jwt = jwtDecode<any>(state.auth.token.access_token);
          if (jwt && jwt.exp) {
            const currentTime = new Date().getTime() / 1000;
            if (currentTime < jwt.exp) {
              if (state.config.configuration) {
                const refreshResult = await refreshToken(state.config.configuration.oAuth, state.auth.token.refresh_token);
                if (refreshResult !== undefined) {
                  store.dispatch(login(refreshResult));
                  return refreshResult.access_token;
                }
              }
            }
            return state.auth.token?.access_token;
          }
          return '';
        }
      };

      const config = store.getState().config.configuration;
      if (config !== undefined) {
        if (connection === undefined) {
          connection = new HubConnectionBuilder()
            .withUrl(`${config.api.baseUrl}/EngineHub`, {
              accessTokenFactory: getToken,
              transport: HttpTransportType.WebSockets,
              skipNegotiation: true,
            })
            .withAutomaticReconnect({
              nextRetryDelayInMilliseconds(): number | null {
                return 2000;
              },
            })
            .build();

          connection.on('Kill', (workstationId: number) => {
            AppLogger.warn('Kill requested for WS ID ' + workstationId);
            if (store.getState().appState.workstation?.id === workstationId) {
              AppLogger.warn('This workstation is dead');
              appSubscriptionService.killNext(true);
            }
          });
          connection.on('Restart', () => window.location.reload());
          connection.on('LogoutOperator', (operatorId: number) => {
            AppLogger.info('logout operator', operatorId);
            const currentOperatorSession = store.getState().appState.operatorSession;
            if (currentOperatorSession && currentOperatorSession.operator.id === operatorId) store.dispatch(clearOperatorSession());
          });
          connection.on('NotifyCardPaymentFault', (transactionId: number, transactionNumber: number, error: string) => {
            AppLogger.info('NotifyCardPaymentFault', transactionId, transactionNumber, error);
            store.dispatch(
              showErrorDialog({ message: `Card Payment failed for transaction ${transactionNumber} due to ${error}`, dismissible: true }),
            );
          });
          connection.on('NotifyPrinterFault', (printerName: string, transactionId: number, transactionNumber: number, error: string) => {
            AppLogger.info('onNotifyPrinterFault', printerName, transactionId, transactionNumber, error);
            store.dispatch(
              showErrorDialog({
                message: `Printer Offline!<br/>${printerName}<br/><br/>Transaction: ${transactionNumber}`,
                dismissible: true,
              }),
            );
          });
          connection.on('NotifyPrinterBackup', (printerName: string, transactionId: number, transactionNumber: number) => {
            AppLogger.info('onNotifyPrinterBackup', printerName, transactionId, transactionNumber);
            store.dispatch(
              showErrorDialog({
                message: `Warning! Printed to backup.<br/>${printerName} is offline.<br/><br/>Transaction: ${transactionNumber}`,
                dismissible: true,
              }),
            );
          });

          connection.on('PollServerState', (serverDate: string, menuVersion: number, layoutVersion: number, packageVersion: number) => {
            AppLogger.info(`SignalR Server State ${serverDate}`);
            store.dispatch(setPollData({ date: serverDate, layoutDefinitionVersion: layoutVersion, menuVersion, packageVersion }));
            appSubscriptionService.layoutVersionNext(layoutVersion);
          });

          connection.on(
            'CardPaymentSuccess',
            (transactionId: number, transactionNumber: number, sequenceNumber: number, result: string) => {
              AppLogger.info(`Card Payment success result for ${transactionId} which is ${transactionNumber}/${sequenceNumber}`);
              appSubscriptionService.cardPaymentSuccessNext({ transactionId, result });
            },
          );

          connection.on(
            'CardSignatureRequest',
            (
              transactionId: number,
              transactionNumber: number,
              sequenceNumber: number,
              operatorName: string,
              terminalId: string,
              verificationRequestId: number,
            ) => {
              AppLogger.info(`Card Signature request for ${transactionId} on ${terminalId}`);
              appSubscriptionService.cardSignatureNext({
                sequenceNumber: sequenceNumber,
                transactionNumber: transactionNumber,
                terminalId: terminalId,
                verificationRequestId: verificationRequestId,
              });
            },
          );

          connection.on(
            'CardPaymentFailure',
            (
              transactionId: number,
              transactionNumber: number,
              sequenceNumber: number,
              operatorName: string,
              amount: number,
              reason: string,
              allowManualEntry: boolean,
              cardRequestId: number | null,
            ) => {
              AppLogger.info('Card Payment failure result for ' + transactionId);
              const priceFormatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP',
              });

              let extraText = '';
              if (reason == 'TimedOut')
                extraText = '<br>Communication with the PDQ has failed, please check card receipt and record accordingly';
              if (allowManualEntry) {
                extraText = '<br>Please check card receipt and record accordingly';
                store.dispatch(
                  showErrorDialog({
                    errorType: 'card-payment-failure',
                    message: `Card payment failure for ${transactionNumber}/${sequenceNumber}.<br><br> Operator was <b>${operatorName}</b><br>Value <b>${priceFormatter.format(
                      amount,
                    )}</b><br><br>Card Result was ${reason}<br>${extraText}`,
                    dismissible: true,
                    buttonText: 'Payment Failed',
                    backgroundColor: 'red',
                    alternateButtonText: 'Payment Completed',
                    showAlternateButton: true,
                    requireConfirmationOnClose: true,
                    confirmationText: 'Please confirm this payment did NOT succeed. You will need to resolve the issue.',
                    confirmationTextAlternate:
                      'Please confirm the payment succeeded, you will need to enter the Auth Code off the card machine.',
                    state: {
                      transactionId,
                      transactionNumber,
                      sequenceNumber,
                      operatorName,
                      amount,
                      reason,
                      cardRequestId,
                      message: `Card payment failure for ${transactionNumber}/${sequenceNumber}.<br><br> Operator was <b>${operatorName}</b><br>Value <b>${priceFormatter.format(
                        amount,
                      )}</b><br><br>Card Result was ${reason}<br>${extraText}`,
                      type: 'card-failure',
                    },
                    alternateState: {
                      transactionId,
                      transactionNumber,
                      sequenceNumber,
                      operatorName,
                      amount,
                      reason,
                      cardRequestId,
                      message: `Card payment failure for ${transactionNumber}/${sequenceNumber}.<br><br> Operator was <b>${operatorName}</b><br>Value <b>${priceFormatter.format(
                        amount,
                      )}</b><br><br>Card Result was ${reason}<br>${extraText}`,
                      type: 'offline-card',
                    },
                  }),
                );
              } else {
                store.dispatch(
                  showErrorDialog({
                    message: `Card payment failure for ${transactionNumber}/${sequenceNumber}.<br><br> Operator was <b>${operatorName}</b><br>Value <b>${priceFormatter.format(
                      amount,
                    )}</b><br><br>Card Result was ${reason}<br>${extraText}`,
                    dismissible: true,
                    buttonText: 'Acknowledge',
                    backgroundColor: 'red',
                    requireConfirmationOnClose: true,
                    confirmationText: 'The card payment looks to have failed. Please confirm this and ensure that the card machine is OK',
                  }),
                );
              }
              appSubscriptionService.cardPaymentFailureNext(transactionId);
            },
          );
          connection.on('CardPaymentStatus', (transactionId: number, notification: string, terminalId: string) => {
            AppLogger.info(`Card Payment Status result for ${transactionId} which is ${notification} on terminal ${terminalId}`);
            appSubscriptionService.cardNotificationNext({ transactionId, notification, terminalId });
          });

          connection.on('NewMenuVersionData', (siteId: number, menuVersion: MenuVersion) => {
            console.info('GOT NEW MENU VERSION', JSON.stringify(menuVersion));
            //store.dispatch(setMenuItems(menuVersion.menu));
            //store.dispatch(setMenuItemCondiments(menuVersion.condiments));
            store.dispatch(setMenuVersion(menuVersion));
          });

          connection.on('NewPackageData', (siteId: number, packages: string) => {
            console.info('GOT NEW PACKAGE DATA', packages);
            store.dispatch(setPackages(JSON.parse(packages)));
          });

          connection.on('NewUnitOfSaleData', (siteId: number, uos: string) => {
            console.info('GOT NEW UOS', JSON.stringify(uos));
            store.dispatch(setUnitOfSales(JSON.parse(uos)));
          });

          connection.on('NewLayoutData', (siteId: number, layoutDefinitions: string) => {
            console.info('GOT NEW LAYOUT DEFINITIONS', JSON.stringify(layoutDefinitions));
            store.dispatch(setLayoutDefinitions(JSON.parse(layoutDefinitions)));
          });

          connection.on('NewCondimentData', (siteId: number, condiments: string) => {
            console.info('GOT NEW CONDIMENT', JSON.stringify(condiments));
            store.dispatch(setMenuItemCondiments(JSON.parse(condiments)));
          });

          connection.on('NewTenderMediaData', (siteId: number, tenders: string) => {
            console.info('GOT NEW TENDERS', JSON.stringify(tenders));
            store.dispatch(setTenderMedias(JSON.parse(tenders)));
          });

          connection.on('Test', () => {
            appSubscriptionService.testNext(new Date().getTime());
          });

          connection.on('NotifyTillSessionBlockNewTransactions', (workstationSessionId: number) => {
            console.info('WORKSTATION IS BLOCKED', workstationSessionId);
            store.dispatch(setWorkstationBlocked(true));
          });
          connection.on('NotifyTillSessionClosed', (workstationSessionId: number) => {
            console.info('WORKSTATION IS BLOCKED', workstationSessionId);
            store.dispatch(setWorkstationClosed(true));
          });

          connection.on(
            'CardPaymentLastResultStatus',
            (
              terminalNumber: string,
              transactionNumber: number,
              amount: number,
              success: boolean,
              operatorName: string,
              terminalName: string,
            ) => {
              appSubscriptionService.cardResultStatusNext({
                success,
                terminalId: terminalNumber,
                transactionNumber: transactionNumber,
                lastAmount: amount,
                dateTimeAdded: new Date().getTime(),
                operatorName,
                terminalName,
              });
            },
          );

          connection.onclose((error) => {
            AppLogger.error('SignalR error', error);
            store.dispatch(setSignalrStatus(false));
            store.dispatch(
              showErrorDialog({
                message: 'Disconnected from POS Engine',
                dismissible: false,
                state: {
                  type: 'signalr-disconnected',
                },
                alternateButtonText: 'Reboot',
                showAlternateButton: true,
              }),
            );
          });

          connection.onreconnecting((error) => {
            AppLogger.error('SignalR reconnecting', error);
            store.dispatch(
              showErrorDialog({
                message: `Disconnected from POS Engine<br /><br />Retrying every second`,
                dismissible: false,
              }),
            );
          });

          connection.onreconnected(() => {
            AppLogger.info('SignalR reconnected');
            connection?.invoke('Connect').then((data: boolean) => {
              if (data === true) {
                AppLogger.info('Reconnected to POS SignalR with Workstation ACK');
                store.dispatch(setSignalrStatus(true));
                store.dispatch(clearErrorDialog());
              } else AppLogger.warn('SignalR Failed to ack');
            });
          });
        }

        if (connection.state === HubConnectionState.Disconnected) {
          connection.start().then(() => {
            AppLogger.info('Connected to SignalR');

            connection?.invoke('Connect').then((data: boolean) => {
              if (data === true) {
                AppLogger.info('Connected to POS SignalR with Workstation ACK');
                store.dispatch(setSignalrStatus(true));
                store.dispatch(clearErrorDialog());
              } else AppLogger.warn('SignalR Failed to ack');
            });
          });
        } else {
          connection.stop().then(() => {
            if (connection === undefined) return;
            connection.start().then(() => {
              AppLogger.info('Connected to SignalR');

              connection?.invoke('Connect').then((data: boolean) => {
                if (data === true) {
                  AppLogger.info('Connected to POS SignalR with Workstation ACK');
                  store.dispatch(setSignalrStatus(true));
                  store.dispatch(clearErrorDialog());
                } else AppLogger.warn('SignalR Failed to ack');
              });
            });
          });
        }
      }
    } else if (action.type === 'signalr/disconnect')
      if (connection !== undefined) connection.stop().then(() => store.dispatch(setSignalrStatus(false)));

    next(action);
  };
};

export default signalrMiddleware;
