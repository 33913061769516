import { ReactNode } from 'react';

import { useAppStateIsInitialised, useAppStateLoadErors } from '../../../features/appState';
import ApplicationStateManager from '../../../features/appState/components/ApplicationStateManager';
import AuthVerify from '../../../features/auth/components/AuthVerify';
import CardFailureConfirmation from '../../../features/card-failure-confirmation/CardFailureConfirmation';
import ErrorDialog from '../../../features/error-dialog/ErrorDialog';
import ModalDialog from '../../../features/modal/ModalDialog';
import OfflineCardPayment from '../../../features/offline-card-payment/OfflineCardPayment';
import PacSignatureVerify from '../../../features/pac-signature-verify/PacSignatureVerify';
import Loader from '../loader/Loader';
import StateLoadErrors from '../state-load-errors/StateLoadErrors';
import { WakeLockController } from '../wake-lock/WakeLockController';

import EnvironmentBanner from './EnvironmentBanner';
import { PollError } from './PollError';

type LayoutProps = {
  element?: ReactNode;
};
function Layout({ element }: LayoutProps) {
  const appInitialised = useAppStateIsInitialised();
  const appStateErrors = useAppStateLoadErors();

  return (
    <>
      <WakeLockController />
      <AuthVerify />
      <ApplicationStateManager />
      {appStateErrors.length > 0 && <StateLoadErrors />}
      {appInitialised && element}
      {appInitialised === false && appStateErrors.length === 0 && <Loader />}
      <ErrorDialog />
      <PacSignatureVerify />
      <OfflineCardPayment />
      <CardFailureConfirmation />
      <ModalDialog />
      <PollError />
      <EnvironmentBanner />
    </>
  );
}

export default Layout;
