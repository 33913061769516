import { useState } from 'react';

import Price from '../../common/components/price/Price';

export default function CardFailureConfirmationAmount({ onAmountConfirmed }: { onAmountConfirmed: (amount: number) => void }) {
  const [amount, setAmount] = useState('0');
  return (
    <div className="pos-modal pos-modal_role_new-table">
      <div className="pos-modal__content">
        <div className="pos-new-table pos-pin-entry-devices">
          <div className="pos-new-table__header">
            <div className="pos-pin-entry-devices__header">
              <h2 className="pos-pin-entry-devices__title">Confirm Amount</h2>
              <p className="pos-pin-entry-devices__subtitle">Please enter the amount off the card machine that was last successful.</p>
            </div>
          </div>
          <div className="pos-new-table__controls">
            <div className="pos-new-table__number card-failure-number">
              <p className="pos-new-table__number-label">Amount</p>
              <div className="pos-new-table__number-display">
                <Price price={Number(amount) / 100} />
              </div>
            </div>
            <div className="pos-new-table__numpad card-failure-numpad">
              <div className="pos-numpad pos-numpad_role_new-table">
                <div className="pos-numpad__controls">
                  <div className="pos-numpad__controls-row">
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '1')}>
                      1
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '2')}>
                      2
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '3')}>
                      3
                    </button>
                  </div>
                  <div className="pos-numpad__controls-row">
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '4')}>
                      4
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '5')}>
                      5
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '6')}>
                      6
                    </button>
                  </div>
                  <div className="pos-numpad__controls-row">
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '7')}>
                      7
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '8')}>
                      8
                    </button>
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '9')}>
                      9
                    </button>
                  </div>
                  <div className="pos-numpad__controls-row">
                    <button className="pos-numpad__control" type="button" onClick={() => setAmount(amount + '0')}>
                      0
                    </button>
                    <button className="pos-numpad__control pos-numpad__control_role_clear" type="button" onClick={() => setAmount('')}>
                      CLR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-new-table__actions">
            <button
              className="pos-new-table__action"
              type="button"
              disabled={Number(amount) <= 0}
              onClick={() => onAmountConfirmed(Number(amount) / 100)}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
