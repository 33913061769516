import { baseApi } from '../baseApi';

import { PacSignatureVerifyRequest } from './Models';
import { PinEntryDevice } from './PinEntryDevice';

const pinEntryDeviceSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPinEntryDevices: builder.query<PinEntryDevice[], boolean | undefined>({
      keepUnusedDataFor: 0,
      query: (showAll: boolean | undefined) => ({
        url: '/PinEntryDevices?showAll=' + (showAll === true ? 'true' : 'false'),
        method: 'GET',
        headers: {
          'x-api-version': '1.0',
        },
      }),
    }),
    pacSignatureVerify: builder.mutation<void, PacSignatureVerifyRequest>({
      query: (request: PacSignatureVerifyRequest) => ({
        url: `/PinEntryDevices/SignatureVerify?verificationRequestId=${request.verificationRequestId}&accepted=${request.accepted}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetPinEntryDevicesQuery, useLazyGetPinEntryDevicesQuery, usePacSignatureVerifyMutation } = pinEntryDeviceSlice;
